export const formatParticipantHeadingObject = (participant: object, includeNationalities = true) => {
  if (!participant) { return null }

  const nationalities = participant?.nationalities?.map(i => i?.nationality?.toLowerCase() === 'xk' ? 'RKS' : i?.nationality )?.filter(Boolean)?.join('/')
  const title = [
    participant?.collectiveName?.trim() || participant?.names?.trim(),
    includeNationalities ? nationalities : null
  ].filter(Boolean).join(', ')

  return {
    title,
    subtitle: participant?.collectiveName && participant?.names ? participant?.names?.trim() : null,
    annotation: participant?.annotation?.trim()
  }
}
